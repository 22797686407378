/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import C1 from "./calendarimg/2014-1.jpg"
import C2 from "./calendarimg/2014-2.jpg"
import IRBG from "../../../components/IRBG"

import CalendarNav from "../../../components/CalendarNav"
import HomeLogo from "../../../components/HomeLogo"

import IRNav from "../../../components/IRNav"

export default class CalendarPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Calendar - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Corporate Calendar
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <CalendarNav />
          <div className="column is-offset-2">
            <div className="columns" id="calendar-line">
              <div className="column is-1">
                <img src={C1} />
              </div>
              <div className="column">
                <h1 className="subtitle">Initial Public Offering</h1>
                <p>
                  E.A.TECHNIQUE (M) BERHAD (EATECH, 5259) was successfully
                  listed on the MAIN MARKET of Bursa Securities under the
                  "Trading/Services" sector on 11 December 2014.
                  <br />
                  <br />
                  Details of the enlarged issued and paid-up share capital is as
                  follows:
                  <br />
                  <br />
                  Units : 504,000,000
                  <br />
                  Currency : MYR 126,000,000.000
                  <br />
                  Par Value ($$) : MYR 0.250
                  <br />
                  ISIN code : MYL5259OO006
                </p>
              </div>
            </div>
          </div>
          <div className="column is-offset-2" id="calendar-line">
            <div className="columns">
              <div className="column is-1">
                <img src={C2} />
              </div>
              <div className="column">
                <h1 className="subtitle">TIMETABLE FOR IPO</h1>
                <p>
                  The indicative timing of events leading up to the listing of
                  and quotation for our entire enlarged issued and fully paid-up
                  share capital on the Main Market of Bursa Malaysia Securities
                  Berhad is set out below:
                  <br />
                  <br /> Opening of application - 24/11/2014
                  <br /> Closing of application - 01/12/2014
                  <br /> Balloting of applications - 03/12/2014
                  <br /> Allotment of IPO shares to successful applicants -
                  09/12/2014
                  <br /> Tentative listing date - 11/12/2014
                  <br />
                  <br /> All defined terms used herein shall have the same
                  meaning as those defined in the Prospectus dated 24 November
                  2014 issued by E.A. Technique (M) Berhad.
                  <br />
                  <br /> This timetable is tentative and is subject to changes
                  which may be necessary to facilitate implementation
                  procedures. The application will open at 10.00 a.m. on 24
                  November 2014 and will close at 5.00 p.m. on 1 December 2014
                  or such later date as our Directors, Promoters and Selling
                  Shareholders together with our Underwriter in their absolute
                  discretion may mutually decide.
                  <br />
                  <br /> In the event the closing date of the application is
                  extended, we will advertise a notice of the extension in a
                  widely circulated English and Bahasa Malaysia daily newspaper
                  within Malaysia. Following this, the dates for the balloting
                  of applications for the IPO Shares, allotment of the IPO
                  Shares and the Listing may be extended accordingly
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
